import DocxBuilder from "../docxgen/DocxBuilder";



export default class CoverLetterReportBuilder extends DocxBuilder {
    constructor() {
        super();
        this.aiOptimizedPhrasing = "";
    }

    CreateText(aiOptimizedPhrasing) {

        this.aiOptimizedPhrasing = aiOptimizedPhrasing;
    }

    AddFormatedLines(text) {
        if( !text == "" )
        {
            const lines = text.split('\n');
            lines.forEach(line => {
                this.AddParagraphEmpty(line);
            });
        }
    }

    BuildDocx() {
        this.AddFormatedLines(this.aiOptimizedPhrasing);
        return this.BuildSection().GetBlob();
    }
}